/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import partnersLogo from "../assets/partners.svg";
import { openModal, setQuantity } from "../../../features";
import start from "../assets/star.png";
import shield from "../assets/sld.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Load } from "./SkeletonLoader";
// import TrialWorks from "../components/TrialWork";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const PlanCard = ({
  onClick,
  promoData,
  isPlanAvailable,
  totalAmount,
  allPlans,
  uId,
  isLoading,
  cardPage,
  onAppPromo,
  setpromoData,
  coupon,
  planId,
  setPromoCode,
  setPage,
  // promoCode,
}) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [disable, setDisable] = useState(false);
  const { isFormSubmitting } = useSelector((store) => store.auth);
  const [isdisabled, setIsDisabled] = useState(false);
  const [trialStatus, setTrialStatus] = useState(false);

  const { planDetails, currentPlan, quantity } = useSelector(
    (store) => store.auth,
  );
  const currentPage = searchParams.get("page");
  const queryParams = {
    deals: searchParams.get("deals"),
    plan_id: searchParams.get("plan_id"),
  };

  const plan_Id = {
    s_1: "s_1",
    p_1: "p_1",
    pr_1: "pr_1",
    ul_1: "ul_1",
  };
  const couponCode = {
    hbvjikg: "hbvjikg",
  };
  useEffect(() => {
    if (searchParams.get("offers") === "off_50") {
      setTrialStatus(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!currentPage) return;
    currentPage && setPage(2);
    !currentPage && setPage(1);
  }, []);

  useEffect(() => {
    if (!currentPage) return;
    if (
      plan_Id[queryParams.plan_id] === queryParams.plan_id &&
      couponCode[queryParams.deals] === queryParams.deals &&
      plan_Id[currentPlan]
    ) {
      const promo_code = {
        code: "hbvjikg",
      };
      setpromoData(promo_code);
      setIsDisabled(true);
    } else if (searchParams.get("offers") === "off_50") {
      setIsDisabled(true);
    } else {
      const promo_code = {
        code: "",
      };
      setpromoData(promo_code);
    }
  }, [currentPlan, currentPage]);
  const planName = planDetails?.[currentPlan]?.name;
  const planType = planDetails?.[currentPlan]?.planType;

  useEffect(() => {
    if (!coupon) return;
    (async () => {
      try {
        const { data } = await axios.get("/promo", {
          params: {
            code: coupon,
            plan: currentPlan || planId || "s_1",
            dpu: quantity,
          },
        });
        if (data?.status === "error") {
          toast.error(data?.message);
        }
        setpromoData(data?.results);
        toast.success(data?.results?.description);
      } catch (error) {
        toast.error(error.message);
      }
    })();
  }, [coupon, planId, setpromoData]);

  console.log("planType", planType, currentPlan);
  return (
    <Wrapper
      id="w-node-_4afac97e-2cfa-064d-c244-4692e3d8704d-a1efd709"
      className="co-grid-right margin-small"
    >
      <div className="co-sidebar-wrapper">
        <div className="co-sidebar">
          <div className="div-block-182">
            <div className="co-cart-title" id="__plan_name">
              Your plan
            </div>
            <div
              id="__change_plan"
              className="co-cart-title button"
              onClick={() => dispatch(openModal())}
            >
              Change plan
            </div>
          </div>
          <div className="sn-row color--gray-700">
            <div
              className="sn-label color--black semi-bold"
              // style={{ fontSize: "14px" }}
            >
              {planName ? `${planName} plan` : <Load variant="amount" />}
            </div>
            <div className="sn-value color--black semi-bold" id="__total">
              {Object.keys(planDetails).length ? (
                <>
                  $
                  {trialStatus
                    ? planType === "Monthly"
                      ? planDetails?.[currentPlan]?.offer_price
                      : planDetails?.[currentPlan]?.offer_price
                    : planDetails?.[currentPlan]?.offer_price}{" "}
                  <span
                    style={{ color: "#7d7d7d", fontSize: "14px !important" }}
                  >
                    {planType === "Monthly" && trialStatus && <span>/m</span>}{" "}
                    {planType !== "Monthly" && trialStatus && <span>/m</span>}{" "}
                    {!trialStatus && <span>/m</span>}{" "}
                  </span>
                </>
              ) : (
                <Load variant="amount" />
              )}
            </div>
          </div>
          <div className="divider margin-top-default" />
          <div className="p-package-benefits-list" id="__benefits_list">
            <div className="p-package-benefits-item co-benefits-item">
              <div className="p-package-benefits-item-text">ADD ONS</div>
            </div>
            <div className="p-package-benefits-item co-benefits-item">
              <div className="p-package-benefits-item-text">Graphic Design</div>
              <div className="incl">INCLUDED</div>
            </div>
            <div className="p-package-benefits-item co-benefits-item">
              <div className="p-package-benefits-item-text">
                Custom Illustrations
              </div>
              <div className="incl">INCLUDED</div>
            </div>
            {(planName === "Pro" ||
              planName === "Premium" ||
              planName === "Ultimate") && (
              <div className="p-package-benefits-item co-benefits-item">
                <div className="p-package-benefits-item-text">
                  Presentation Design
                </div>
                <div className="incl">INCLUDED</div>
              </div>
            )}
            {(planName === "Premium" || planName === "Ultimate") && (
              <div className="p-package-benefits-item co-benefits-item">
                <div className="p-package-benefits-item-text">
                  Motion Graphics
                </div>
                <div className="incl">INCLUDED</div>
              </div>
            )}

            {planName === "Ultimate" && (
              <div className="p-package-benefits-item co-benefits-item">
                <div className="p-package-benefits-item-text">UI design</div>
                <div className="incl">INCLUDED</div>
              </div>
            )}

            {/* hr */}
            <div className="divider margin-top-default" />

            <div className="p-package-benefits-item co-benefits-item">
              <div className="p-package-benefits-item-text">
                Professional Designers
              </div>
              <div className="incl">INCLUDED</div>
            </div>

            <div className="p-package-benefits-item co-benefits-item">
              <div className="p-package-benefits-item-text">
                1 Project at a time
              </div>
              <div className="incl">INCLUDED</div>
            </div>

            <div className="p-package-benefits-item co-benefits-item">
              <div className="p-package-benefits-item-text">
                Dedicated Designer
              </div>
              <div className="incl">INCLUDED</div>
            </div>

            {(planName === "Premium" ||
              planName === "Ultimate" ||
              planName === "Pro") && (
              <>
                {/* <div className="p-package-benefits-item co-benefits-item">
                  <div className="p-package-benefits-item-text">
                    Dedicated Designer
                  </div>
                  <div className="incl">INCLUDED</div>
                </div> */}
                <div className="p-package-benefits-item co-benefits-item">
                  <div className="p-package-benefits-item-text">
                    Dedicated Account Manager
                  </div>
                  <div className="incl">INCLUDED</div>
                </div>
              </>
            )}
          </div>
          {/* Discount para */}
          <div
            className="divider margin-top-default"
            id="__discount_info_divider"
            style={{ display: "none" }}
          />
          <div
            className="sn-row color--gray-700"
            id="__discount_info"
            style={{ display: "none" }}
          >
            <div className="sn-label">Discount</div>
            <div className="sn-value text-color-green" id="__discount">
              -$0
            </div>
          </div>
          <div className="divider margin-top-default" />
          <div className="sn-row color--gray-700">
            <div
              className="sn-label color--black semi-bold"
              style={{ fontSize: "16px" }}
            >
              Subscription
            </div>
            <div className="sn-value color--black semi-bold">
              <p className="qty">
                <Button>
                  <button
                    // className="qtyminus"
                    aria-hidden="true"
                    onClick={() => {
                      dispatch(setQuantity("DEC"));
                    }}
                  >
                    −
                  </button>
                </Button>
                <input
                  type="number"
                  name="qty"
                  id="qty"
                  min={1}
                  step={1}
                  value={quantity}
                />
                <Button>
                  <button
                    // className="qtyplus"
                    aria-hidden="true"
                    onClick={() => {
                      dispatch(setQuantity("INC"));
                    }}
                  >
                    +
                  </button>
                </Button>
              </p>
            </div>
          </div>
          <div className="divider margin-top-default" />
          {trialStatus && (
            <div className="sn-row color--gray-700">
              <div
                className="sn-label color--black semi-bold"
                style={{ color: "#878e96" }}
              >
                {planType === "Monthly" ? (
                  "After 1 month"
                ) : (
                  <>
                    {" "}
                    After 1<sup>st</sup>&nbsp;{planType?.toLowerCase()}
                  </>
                )}
              </div>
              <div
                className="sn-value color--black semi-bold"
                id="__total"
                style={{ color: "#878e96" }}
              >
                {Object.keys(planDetails).length && planType === "Monthly" ? (
                  <>
                    $
                    {Number.isInteger(
                      planDetails?.[currentPlan]?.total_actual_price * quantity,
                    ) === true
                      ? planDetails?.[currentPlan]?.total_actual_price *
                        quantity
                      : planDetails?.[currentPlan]?.total_actual_price *
                        quantity}
                    <span style={{ fontSize: "12px", lineHeight: "20px" }}>
                      /m
                    </span>
                  </>
                ) : (
                  <>
                    {Object.keys(planDetails)?.length ? (
                      <>
                        $
                        {planDetails[currentPlan]?.total_actual_price *
                          quantity}
                        {/* <span style={{ fontSize: "12px", lineHeight: "20px" }}>
                          /1<sup>st</sup>
                          {planType}
                        </span> */}
                      </>
                    ) : (
                      <Load variant="amount" />
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {/* {(planType === "Yearly" || planType === "Quarterly") && ( */}
          {/* {planType === "Monthly" && trialStatus && (
            <>
              <div className="sn-row color--gray-700">
                <div
                  className="sn-label color--black semi-bold"
                  style={{ color: "#878e96" }}
                >
                  After 1 month
                </div>
                <div
                  className="sn-value color--black semi-bold"
                  id="__total"
                  style={{ color: "#878e96" }}
                >
                  {Object.keys(planDetails).length ? (
                    <>
                      ${planDetails?.[currentPlan]?.next_month * quantity}
                      <span style={{ fontSize: "12px", lineHeight: "20px" }}>
                        /mon
                      </span>
                    </>
                  ) : (
                    <Load variant="amount" />
                  )}
                </div>
              </div>
            </>
          )} */}
          {trialStatus && (
            <div className="sn-row color--gray-700">
              <div
                className="sn-label color--black semi-bold"
                // style={{ fontSize: "14px" }}
              >
                Discount
              </div>
              <div className="sn-value color--black semi-bold" id="__total">
                {Object.keys(planDetails).length ? (
                  <>
                    $
                    {Number.isInteger(
                      planDetails?.[currentPlan]?.discount * quantity,
                    ) === true
                      ? planDetails?.[currentPlan]?.discount * quantity
                      : planDetails?.[currentPlan]?.discount * quantity}
                  </>
                ) : (
                  <Load variant="amount" />
                )}
              </div>
            </div>
          )}
          {/* <div className="sn-row color--gray-700">
            <div
              className="sn-label color--black semi-bold"
              // style={{ fontSize: "14px" }}
            >
              Discount
            </div>
            <div className="sn-value color--black semi-bold" id="__total">
              {Object.keys(planDetails).length ? (
                <>${planDetails?.[currentPlan]?.discount * quantity}</>
              ) : (
                <Load variant="amount" />
              )}
            </div>
          </div> */}
          <div className="sn-row color--gray-700">
            <div
              className="sn-label color--black semi-bold"
              // style={{ fontSize: "14px" }}
            >
              Billed today
            </div>
            <div className="sn-value color--black semi-bold" id="__total">
              {Object.keys(planDetails).length ? (
                <>${planDetails?.[currentPlan]?.total_offer_price * quantity}</>
              ) : (
                <Load variant="amount" />
              )}
            </div>
          </div>
          {cardPage === 2 && (
            <>
              <button
                disabled={!uId || isLoading || isFormSubmitting}
                type="submit"
                id="__btn_next"
                className="mgt2 button-large padding-1em w-inline-block btn1"
                form="paymentForm"
                style={{ background: isFormSubmitting ? "#2c2c2c" : "" }}
              >
                {(!uId || isLoading || isFormSubmitting) && (
                  <>
                    <span className=" button-text-large loader"></span>
                    {"  "}
                  </>
                )}
                {!trialStatus && !!Object.keys(planDetails).length && (
                  <>
                    Pay {allPlans?.currency}
                    {(!promoData || !isPlanAvailable) &&
                      currentPlan &&
                      (
                        Number(planDetails[currentPlan]?.total_offer_price) *
                        Number(quantity)
                      )?.toLocaleString()}
                  </>
                )}
                {!!Object.keys(planDetails).length && trialStatus && (
                  <>
                    Pay {allPlans?.currency}
                    {(!promoData || !isPlanAvailable) &&
                      currentPlan &&
                      (
                        Number(planDetails[currentPlan]?.total_offer_price) *
                        Number(quantity)
                      )?.toLocaleString()}
                  </>
                )}
                {!trialStatus &&
                  promoData &&
                  isPlanAvailable &&
                  totalAmount.toLocaleString()}
                &nbsp;
              </button>
            </>
          )}
          {cardPage === 0 && (
            <>
              <button
                id="__btn_next"
                className="mgt2 button-large padding-1em w-inline-block btn1"
                onClick={onClick}
                disabled={isFormSubmitting}
                type="submit"
                form="createAccountForm"
              >
                {isFormSubmitting ? (
                  <>
                    <span className=" button-text-large loader"></span>
                    {"  "} Loading
                  </>
                ) : (
                  <div className="button-text-large">Continue</div>
                )}
              </button>
              {/* {trialStatus && (
                <div className="trial-works">
                  <TrialWorks />
                </div>
              )} */}
            </>
          )}
          {cardPage === 1 && (
            <>
              <button
                id="__btn_next"
                className="mgt2 button-large padding-1em w-inline-block btn1"
                onClick={() => {
                  searchParams.append("page", 2);
                  searchParams.set("plan_id", currentPlan || "s_1");
                  navigate({
                    pathname: "/confirm-plan",
                    search: createSearchParams(searchParams).toString(),
                  });
                  setPage(2);
                }}
              >
                <span className="button-text-large">Continue</span>
              </button>
              {/* {trialStatus && (
                <div className="trial-works">
                  <TrialWorks />
                </div>
              )} */}
            </>
          )}
          {cardPage === 2 && (
            <>
              <div
                className="discount-box"
                id="__discount_box"
                style={{ display: "flex" }}
              >
                <input
                  style={{
                    border: "none",
                    width: "80%",
                    fontSize: "14px",
                    cursor: isdisabled ? "not-allowed" : null,
                  }}
                  type="text"
                  name="__coupon_code"
                  id="__coupon_code"
                  placeholder="Coupon code"
                  onChange={(e) => {
                    setPromoCode(e.target.value);
                    if (disable) {
                      setDisable(false);
                    }
                  }}
                  disabled={isdisabled}
                />
                {/* <div className="discount-right" id="__applied_respone">
                <img
                  src={check}
                  loading="lazy"
                  alt=""
                  className="ico-check-teal"
                />
                <div
                  className="inter_14-20 text-color-green"
                  id="__applied_respone_info"
                >
                  APPLIED
                </div>
              </div> */}

                <button
                  className="button-2 w-button"
                  id="__apply_coupon"
                  onClick={() => {
                    if (!isPlanAvailable) {
                      setDisable(true);
                    }
                    onAppPromo();
                  }}
                  style={{
                    cursor: isdisabled ? "not-allowed" : null,
                    color: isdisabled ? "#808080" : null,
                  }}
                  disabled={
                    (promoData && isPlanAvailable && disable) || isdisabled
                  }
                >
                  {promoData && isPlanAvailable && disable
                    ? "APPLIED"
                    : "APPLY"}
                </button>
              </div>
              {/* {trialStatus && (
                <div className="trial-works">
                  <TrialWorks />
                </div>
              )} */}
            </>
          )}
        </div>
        {!trialStatus && (
          <div
            className="co---guarantee"
            id="__30_day"
            style={{ display: "block" }}
          >
            <div
              className="co---guarantee---content"
              style={{ cursor: "pointer" }}
            >
              {/* <img src={shield} loading="lazy" alt="" className="image-93" /> */}
              <div
                className="lab-gasture16-22 steel-orange"
                style={{ cursor: trialStatus ? "pointer" : "default" }}
              >
                7-day money back guarantee
              </div>
            </div>
          </div>
        )}

        <div
          className="co---guarantee"
          id="__30_day"
          style={{ display: "block" }}
        >
          <div className="co---guarantee---content">
            <img src={start} loading="lazy" alt="" className="image-93" />
            <div className="lab-gasture16-22 steel-orange">
              4.8/5 stars{" "}
              <span style={{ color: "#000" }}>(500 global reviews)</span>
            </div>
          </div>
        </div>
        {cardPage === 2 && (
          <div>
            <img src={partnersLogo} alt="" />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default PlanCard;

const Wrapper = styled.div`
  font-size: 20px;
  .co-cart-title.button {
    font-size: 16px;
  }
`;

const Button = styled.span`
  font-size: 2rem;
  line-height: 1;
  width: 2.5rem;
  height: 2.5rem;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  padding-bottom: 5px;
  button {
    background: transparent;
    padding: 0;
    margin: 0;
    transform: translateY(-2px);
  }
`;
